import { Button, notification, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { SheetData } from 'components/GenericSheetEdit';
import React from 'react';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

interface ExcelActionsProps {
  onImport: (cellsData: SheetData) => void;
  exportData: SheetData;
  translationFn: (s: string) => string;
}

const META_SHEET_NAME = 'META';

const DATA_SHEET_NAME = 'Data';

const CURRENT_EXPORT_VERSION = '1';

const parseBook = (book: XLSX.WorkBook): SheetData => {
  if (!book.SheetNames.includes(META_SHEET_NAME)) {
    // no meta sheet
    return XLSX.utils.sheet_to_json(book.Sheets[book.SheetNames[0]], {
      header: 1
    });
  }
  const metaSheetData: SheetData = XLSX.utils.sheet_to_json(
    book.Sheets[META_SHEET_NAME],
    {
      header: 1
    }
  );
  const dataSheet: SheetData = XLSX.utils.sheet_to_json(
    book.Sheets[DATA_SHEET_NAME],
    {
      header: 1
    }
  );
  const [metaHeaders, metaRow1] = metaSheetData;
  const version = metaRow1[0];
  if (version === '1') {
    const [, ...actualData] = dataSheet;
    return [metaHeaders, ...actualData];
  } else {
    throw Error('Invalid excel export version');
  }
};

const createExport = (
  exportData: SheetData,
  translationFn: (s: string) => string
): XLSX.WorkBook => {
  const book = XLSX.utils.book_new();
  const [rawHeaders, ...actualData] = exportData;
  const translatedHeaders = rawHeaders.map((h) =>
    translationFn(h?.toString() || '')
  );
  const dataSheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...actualData]);
  const metaSheet = XLSX.utils.aoa_to_sheet([
    rawHeaders,
    [CURRENT_EXPORT_VERSION]
  ]);
  XLSX.utils.book_append_sheet(book, dataSheet, DATA_SHEET_NAME);
  XLSX.utils.book_append_sheet(book, metaSheet, META_SHEET_NAME);
  XLSX.utils.book_set_sheet_visibility(book, META_SHEET_NAME, 1);
  return book;
};

export const ExcelActions: React.FC<ExcelActionsProps> = ({
  onImport,
  exportData,
  translationFn
}) => {
  const onExport = (): void => {
    const book = createExport(exportData, translationFn);
    XLSX.writeFile(book, new Date().toISOString() + '.xlsx');
  };

  const uploadProps: UploadProps = {
    customRequest: (options) => {
      const data = options.file;
      (data as Blob).arrayBuffer().then((buf) => {
        let parsed: SheetData;
        try {
          const book = XLSX.read(buf, {
            type: 'buffer'
          });
          parsed = parseBook(book);
        } catch (err) {
          notification['error']({
            message: 'Error occurred',
            description:
              'An error occurred while parsing the excel file. Please contact your administrator.'
          });
          console.error(err);
          return;
        }
        onImport(parsed as SheetData);
      });
    },
    showUploadList: false,
    accept: '.xlsx'
  };
  return (
    <div className="btn-group">
      <Button onClick={onExport}>
        <DownloadOutlined />
        Export
      </Button>
      <Upload {...uploadProps}>
        <Button>
          <UploadOutlined />
          Import
        </Button>
      </Upload>
    </div>
  );
};
