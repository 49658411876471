import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Card, Col, Input, message, PageHeader, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Error, Spinner } from 'lib';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useState } from 'react';
import { useSelectedCustomer } from 'util/selectedCustomer';
import { productQuery, ProductQueryType } from './gql';
import { CopyOutlined } from '@ant-design/icons';

const copyLinkToClipboard = (link: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = link;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  message.success('Integration URL has been copied to clipboard');
};

const INPUT_DEBOUNCE = 200;
const WallpaperQR: React.FC<{ customerId: string }> = ({ customerId }) => {
  const [queryProduct, { loading, data }] = useLazyQuery<
    { product: ProductQueryType | null },
    { productId: string }
  >(productQuery);
  const [debounceHandle, setDebounceHandle] = useState<number | undefined>(
    undefined
  );
  const onInputChange = (newVal: string) => {
    if (debounceHandle) {
      clearTimeout(debounceHandle);
      setDebounceHandle(undefined);
    }
    if (!newVal) {
      return;
    }
    const handle = (setTimeout(() => {
      queryProduct({
        variables: {
          productId: newVal
        }
      });
    }, INPUT_DEBOUNCE) as unknown) as number;
    setDebounceHandle(handle);
  };
  let content: JSX.Element = <></>;
  if (loading) {
    content = <Spinner />;
  } else if (data?.product) {
    const url = `${window.location.origin}/app/${customerId}/wallpaper/${data.product.id}`;
    content = (
      <Card
        cover={<QRCodeCanvas value={url} style={{ margin: '1rem auto' }} />}
      >
        <Meta
          title={'Open Wallpaper - ' + data.product.id}
          description={data.product.externalData.i18n.langMap_en.name}
        />
      </Card>
    );
  } else if (data?.product === null) {
    content = <Error data="Invalid product ID supplied"></Error>;
  }
  return (
    <>
      <Input
        addonBefore="Wallpaper ID"
        placeholder="SKU"
        onChange={(evt) => onInputChange(evt.target.value)}
      />
      {content}
    </>
  );
};
export const HomePage: React.FC = () => {
  const { selectedCustomer } = useSelectedCustomer();
  const integrationUrl =
    window.location.origin +
    `/app/${selectedCustomer?.id}/wallpaper/[WALLPAPER_ID]`;

  if (!selectedCustomer) {
    return null;
  }

  return (
    <>
      <PageHeader
        title="Home"
        subTitle="Start page"
        backIcon={false}
      ></PageHeader>
      <div className="header-padded">
        <Row gutter={16}>
          <Col xs={24} md={12} xl={6}>
            <Paragraph>Input a wallpaper ID to test it in the app</Paragraph>
            <WallpaperQR customerId={selectedCustomer.id} />
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Paragraph>
              Link to this page from your e-commerce to open the Wallpaper
              Visualizer
            </Paragraph>
            <Input.Group>
              <Input
                disabled
                addonAfter={
                  <Button
                    type="text"
                    size="small"
                    icon={<CopyOutlined />}
                    onClick={() => copyLinkToClipboard(integrationUrl)}
                  />
                }
                value={integrationUrl}
              />
            </Input.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};
