import { gql } from 'apollo-boost';

export const productsQuery = gql`
  query {
    allProducts {
      id
      externalData {
        i18n {
          langMap_en: lang(langCode: "en") {
            name
            url
          }
        }
      }
      internalData {
        published
      }
      images {
        uri
      }
      ardata: ARData {
        ... on ARDataImpl {
          imgUri
          imageWidth
          imageHeight
          dropType
          mural
          repeatability
        }
      }
    }
  }
`;

export const saveProductQuery = gql`
  mutation($pjson: String!) {
    saveProduct(jsonData: $pjson) {
      id
    }
  }
`;
