const baseLink = 'https://wallpapervisualizer.page.link/';
export interface Params {
  link: string;
  apn: string;
  efr: 1 | 0;
  ibi: string;
  isi: string;
  st: string;
}
const DEFAULT_PARAMS = {
  apn: 'com.innotactsoftware.wonderwallar.wallpapervisualizer',
  efr: '1',
  ibi: 'InnoTact.Wallpaper.Visualizer',
  isi: '1641464254',
  st: 'Show Wallpaper in AR'
};
export const generateDynamicLink = (link: string): string => {
  const searchParams = new URLSearchParams({
    ...DEFAULT_PARAMS,
    link
  });
  return `${baseLink}?${searchParams.toString()}`;
};
