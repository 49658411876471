export const getDotSeparatedData = (obj: unknown): Record<string, unknown> => {
  const inner = (
    obj: Record<string, unknown>,
    current?: string
  ): [string, unknown][] => {
    return Object.entries(obj).flatMap((e) => {
      let newKey;
      if (Array.isArray(obj)) {
        const arrKey = '[' + e[0] + ']';
        newKey = current ? current + arrKey : arrKey;
      } else {
        newKey = current ? current + '.' + e[0] : e[0];
      }
      const v = e[1];
      if (v && typeof v === 'object') {
        return inner(v as Record<string, unknown>, newKey);
      }
      return [[newKey, v]];
    });
  };
  const entries = inner(obj as Record<string, unknown>);
  return Object.fromEntries(entries);
};

export const fromDotSeparatedData = (
  obj: Record<string, unknown>
): Record<string, unknown> | unknown[] => {
  const arrRegex = /^\[(\d+)\]\.?/;
  const objRegex = /^(\w+)\.?/;
  const pluckPath = (path: string): [string | number, string] => {
    const arrRes = arrRegex.exec(path);
    if (arrRes) {
      return [Number(arrRes[1]), path.slice(arrRes[0].length)];
    }
    const objRes = objRegex.exec(path);
    if (objRes) {
      return [objRes[1], path.slice(objRes[0].length)];
    }
    return [path, ''];
  };

  const isLeaf = (p: string): boolean => pluckPath(p)[1].length === 0;

  const inner = (
    entries: [string, unknown][]
  ): Record<string, unknown> | unknown[] => {
    const leafs = entries.filter((e) => isLeaf(e[0]));
    const nestedGroups = entries
      .filter((e) => !isLeaf(e[0]))
      .map((e) => {
        const [key, rest] = pluckPath(e[0]);
        return [key, [rest, e[1]]] as [string | number, [string, unknown]];
      })
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr[0]]: [...(acc[curr[0]] || []), curr[1]]
        }),
        {} as Record<string | number, [string, unknown][]>
      );
    const nested = Object.entries(nestedGroups).map(
      (group) =>
        [group[0], inner(group[1])] as [string, Record<string, unknown>]
    );
    const allData = [...leafs, ...nested];
    const isNumberKeys =
      allData.map((e) => e[0]).filter((k) => isNaN((k as unknown) as number))
        .length === 0;
    if (isNumberKeys) {
      return allData
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map((e) => e[1]);
    }
    return Object.fromEntries(allData);
  };
  return inner(Object.entries(obj));
};
