import { useEffect } from 'react';

export type OSType = 'Windows Phone' | 'Android' | 'iOS' | undefined;

const getUserAgent = (): string =>
  navigator.userAgent ||
  navigator.vendor ||
  ((window as unknown) as Record<string, string>).opera;

export const getMobileOperatingSystem = (): OSType => {
  const userAgent = getUserAgent();
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (
    /iPad|iPhone|iPod/.test(userAgent) &&
    !((window as unknown) as Record<string, unknown>).MSStream
  ) {
    return 'iOS';
  }
  return undefined;
};

export const getIsSafari = (): boolean => {
  const userAgent = getUserAgent();
  return (
    !/CriOS/.test(userAgent) &&
    !/FxiOS/.test(userAgent) &&
    !/OPiOS/.test(userAgent) &&
    !/mercury/.test(userAgent)
  );
};

export interface LinkData {
  ios?: string;
  android?: string;
}
export const useOpenAppLink = (
  linksData: LinkData,
  shouldOpen: boolean
): void => {
  const iosLink = linksData.ios;
  const androidLink = linksData.android;
  useEffect(() => {
    if (!shouldOpen) {
      return;
    }
    const os = getMobileOperatingSystem();
    if (os === 'iOS' && iosLink) {
      window.location.href = iosLink;
      setTimeout(() => window.history.back(), 1000);
    }
    if (os === 'Android' && androidLink) {
      window.location.href = androidLink;
      setTimeout(() => window.history.back(), 1000);
    }
  }, [shouldOpen, androidLink, iosLink]);
};
