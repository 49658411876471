import { Button, Card, Layout } from 'antd';
import Meta from 'antd/lib/card/Meta';
import Paragraph from 'antd/lib/typography/Paragraph';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect } from 'react';
import { generateDynamicLink } from 'util/dynamicLinkUtil';
import { getIsSafari, getMobileOperatingSystem } from 'util/os-util';
const { Content, Footer } = Layout;

const IOS_APP_LINK =
  'https://apps.apple.com/us/app/wallpaper-visualizer/id1641464254';

const DYNAMIC_LINK = 'https://wallpapervisualizer.page.link/';
const IOSContent: React.FC = () => {
  const isSafari = getIsSafari();
  if (!isSafari) {
    return (
      <>
        <Paragraph
          style={{
            fontSize: '1.3rem'
          }}
        >
          Please install the app and then go back to the previous page and press
          the button again.
        </Paragraph>
        <Button block size="large" target="blank" href={IOS_APP_LINK}>
          Install App
        </Button>
      </>
    );
  }
  return (
    <Paragraph
      style={{
        fontSize: '1.3rem'
      }}
    >
      Please click the app banner to open the wallpaper
    </Paragraph>
  );
};
const Inner: React.FC = () => {
  const os = getMobileOperatingSystem();
  if (os === 'iOS') {
    return <IOSContent />;
  }

  return (
    <Card
      cover={
        <QRCodeCanvas
          value={window.location.href}
          style={{ margin: '1rem auto' }}
        />
      }
    >
      <Meta
        title="Scan on your Device"
        description="Scan this QR code on your smartphone to test the wallpaper in your room."
      />
    </Card>
  );
};
export const AppPage: React.FC = () => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'apple-itunes-app';
    meta.content =
      'app-id=N3QW3RP8Y5, app-clip-bundle-id=InnoTact.Wallpaper.Visualizer.Clip';
    document.getElementsByTagName('head')[0].appendChild(meta);
  });
  useEffect(() => {
    if (document.referrer === DYNAMIC_LINK) {
      if (getMobileOperatingSystem()) {
        setTimeout(() => {
          window.close();
        }, 50);
      }
      return;
    }
    setTimeout(() => {
      window.location.href = generateDynamicLink(window.location.href);
    }, 50);
  }, []);
  return (
    <div className="full-screen">
      <Layout>
        <Content>
          <div className="container">
            <Inner />
          </div>
        </Content>
        <Footer>
          Copyright Innotact Software &copy; {new Date().getFullYear()}
        </Footer>
      </Layout>
    </div>
  );
};
