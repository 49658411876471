import { Modal, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { EditableTable, EditableTableColumn } from 'lib';
import React, { useState } from 'react';
import { GenericSheetEditProps, RowData } from './types';

const { Text, Paragraph } = Typography;

type DataType = Record<string, string | number>;

const buildColumns = (
  headers: RowData,
  translationFn: (s: string) => string
): EditableTableColumn<DataType>[] =>
  headers.map((h) => ({
    title: translationFn(h as string),
    fixed: h === 'id' ? 'left' : false,
    dataIndex: h,
    editable: h === 'id' ? false : true,
    searchable: h === 'id'
  }));

const cellDataToObjs = (rs: RowData[], headers: RowData): DataType[] =>
  rs.map((r) => Object.fromEntries(headers.map((h, i) => [h, r[i]])));

export const AntdSheetEdit: React.FC<GenericSheetEditProps> = ({
  cellData,
  headers,
  translationFn,
  onSaveRow
}) => {
  const dataColumns = buildColumns(headers, translationFn);
  const actionsColumn: ColumnType<DataType> = {
    title: 'Actions',
    dataIndex: 'actions',
    fixed: 'right'
  };
  const [saving, setSaving] = useState(false);

  return (
    <EditableTable
      actionsColumn={actionsColumn}
      columns={dataColumns}
      idDataIndex={'id'}
      data={cellDataToObjs(cellData, headers)}
      saving={saving}
      add={{
        enabled: true,
        getBlankRow: (id: string): Record<string, string | number> =>
          Object.fromEntries(
            headers.map((h) => [h, h === 'id' ? id : undefined])
          )
      }}
      tableProps={{
        scroll: { x: true }
      }}
      saveData={async (id: string, data: DataType): Promise<void> => {
        const dataWithId = {
          id,
          ...data
        };
        setSaving(true);
        try {
          await onSaveRow(dataWithId);
        } catch (e) {
          setSaving(false);
          Modal.warning({
            title: 'Error saving ' + id,
            content: (
              <>
                <Text>An error occurred saving product {id}</Text>
                <Paragraph code>{JSON.stringify(e, null, '\t')}</Paragraph>
              </>
            ),
            width: 1000
          });
          throw e;
        }
        setSaving(false);
      }}
    />
  );
};
