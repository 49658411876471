import React, { useCallback, useEffect, useState } from 'react';
import { PageHeader, Row, Col, notification } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Spinner, Error, useTranslations } from 'lib';
import { productsQuery, saveProductQuery as saveProductMutation } from './gql';
import { Product } from './types';
import {
  AntdSheetEdit,
  RowData,
  SheetData
} from '../../components/GenericSheetEdit';
import { fromDotSeparatedData, getDotSeparatedData } from 'util/ObjectUtils';
import { ExcelActions } from './excel';

const TRANSLATION_PREFIX = 'PRODUCT_';

const ProductSheet: React.FC = () => {
  const {
    loading: loadingFetch,
    error: errorFetch,
    data,
    refetch: refetchProducts
  } = useQuery<{
    allProducts: Product[];
  }>(productsQuery);
  const [saveProduct] = useMutation<
    { saveProduct: { id: string } },
    { pjson: string }
  >(saveProductMutation);
  const [mappedCellData, setMappedCellData] = useState<SheetData>([]);
  const [headerData, setHeaderData] = useState<RowData>([]);
  const { t: rawTranslationFn } = useTranslations(
    'en',
    (headerData as string[]).map((h) => TRANSLATION_PREFIX + h)
  );
  const translationFn = (s: string): string =>
    rawTranslationFn(TRANSLATION_PREFIX + s);
  const handleOnImport = useCallback(
    async (d: SheetData): Promise<void> => {
      const [headers, ...rows] = d;
      const objs = rows
        .map((r) => Object.fromEntries(r.map((rv, i) => [headers[i], rv])))
        .map((or) => fromDotSeparatedData(or));
      const jsons = objs.map((o) => JSON.stringify(o));
      notification['info']({
        message: 'Importing',
        description: 'Importing data of ' + jsons.length + ' products.'
      });
      let errors = 0;
      for (let i = 0; i < jsons.length; i++) {
        try {
          await saveProduct({
            variables: {
              pjson: jsons[i]
            }
          });
        } catch (ex) {
          notification['error']({
            message: 'Error occurred',
            description:
              'Please check the console log or contact your administrator.'
          });
          console.error('Error with row ' + i, ex);
          errors++;
        }
      }
      notification['success']({
        message: 'Successful import',
        description:
          'Imported ' + (jsons.length - errors) + ' products successfully'
      });
      refetchProducts();
    },
    [saveProduct, refetchProducts]
  );
  useEffect(() => {
    if (!data) {
      return;
    }
    const cellObjs = data?.allProducts.map((p) => getDotSeparatedData(p));
    const headers = cellObjs
      .map((c) => Object.keys(c))
      .reduce((acc, curr) => (acc.length >= curr.length ? acc : curr), [])
      .filter((h) => !h.includes('__typename'));
    const expandedCellData = cellObjs.map((co) => headers.map((h) => co[h]));
    const mappedHeaders = headers.map((h) => h.replace('langMap_', 'langMap.'));
    setHeaderData(mappedHeaders);
    setMappedCellData(expandedCellData as SheetData);
  }, [data]);
  // TODO add schema

  if (errorFetch) {
    return <Error data={errorFetch.message} />;
  }
  if (loadingFetch) {
    return <Spinner />;
  }
  return (
    <>
      <ExcelActions
        translationFn={translationFn}
        onImport={handleOnImport}
        exportData={[headerData, ...mappedCellData]}
      />

      <AntdSheetEdit
        translationFn={translationFn}
        cellData={mappedCellData}
        headers={headerData}
        onSaveRow={async (row): Promise<void> => {
          const toSave = fromDotSeparatedData(
            (row as unknown) as Record<string, unknown>
          );
          await saveProduct({
            variables: {
              pjson: JSON.stringify(toSave)
            },
            refetchQueries: [{ query: productsQuery }]
          });
        }}
      />
    </>
  );
};

export const ProductsPage: React.FunctionComponent = () => (
  <>
    <PageHeader
      title="Products"
      subTitle="Show and edit products"
      backIcon={false}
    ></PageHeader>
    <div className="header-padded">
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <ProductSheet />
        </Col>
      </Row>
    </div>
  </>
);
