import { useQuery } from '@apollo/react-hooks';
import { Row, Select } from 'antd';
import { gql } from 'apollo-boost';
import { InjectApolloClient, Error, ProvideSchema } from 'lib';
import React, { useEffect } from 'react';
import { Customer } from 'types/customer';
import { useSelectedCustomer } from 'util/selectedCustomer';

const { Option } = Select;

const query = gql`
  query {
    managedCustomers {
      id
      name
    }
  }
`;

const InnerSelect: React.FC = () => {
  const { loading, error, data } = useQuery<{ managedCustomers: Customer[] }>(
    query
  );
  const {
    selectedCustomer,
    setSelectedCustomer,
    setManagedCustomers,
    managedCustomers
  } = useSelectedCustomer();
  useEffect(() => {
    if (!data) {
      return;
    }
    setManagedCustomers(data.managedCustomers);
  }, [data, setManagedCustomers]);
  if (error) {
    console.error(error);
    return <Error data={error.message} />;
  }
  return (
    <Select
      loading={loading}
      value={selectedCustomer?.id || undefined}
      style={{ width: '100%', margin: '0 0.5rem' }}
      onChange={(v) => setSelectedCustomer(v)}
    >
      {managedCustomers.map((mc) => (
        <Option value={mc.id} key={mc.id}>
          {mc.name}
        </Option>
      ))}
    </Select>
  );
};

export const CustomerSelect: React.FC = () => {
  return (
    <Row justify="center">
      <ProvideSchema>
        <InjectApolloClient>
          <InnerSelect />
        </InjectApolloClient>
      </ProvideSchema>
    </Row>
  );
};
