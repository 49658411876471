import React from 'react';
import './style/main.less';
import logo from './images/logo.png';
import {
  MainLayout,
  Page,
  AuthenticatedRouteBase,
  TranslationsPage
} from 'lib';
import { ProductsPage } from 'pages/Products';
import { ProvideSelectedCustomer } from 'util/selectedCustomer';
import { CustomerSelect } from './components/CustomerSelect';
import { InjectApolloClientCustomer } from 'components/InjectApolloClientCustomer';
import { GraphQLCustomerPage } from 'pages/GraphQLCustomer';
import { HomePage } from 'pages/Home';
import { AppPage } from 'pages/App';
const Logo: React.FC = () => (
  <div className="logo">
    <img src={logo} alt="logo" />
  </div>
);

const pages: Page[] = [
  {
    label: 'Home',
    key: 'home',
    routeComponent: AuthenticatedRouteBase,
    render: (): JSX.Element => (
      <InjectApolloClientCustomer>
        <HomePage />
      </InjectApolloClientCustomer>
    )
  },
  {
    label: 'Products',
    key: 'products',
    routeComponent: AuthenticatedRouteBase,
    render: (): JSX.Element => (
      <InjectApolloClientCustomer>
        <ProductsPage />
      </InjectApolloClientCustomer>
    )
  },
  {
    label: 'Translations',
    key: 'translations',
    routeComponent: AuthenticatedRouteBase,
    allowedRoles: ['ADMIN'],
    render: (): JSX.Element => (
      <InjectApolloClientCustomer>
        <TranslationsPage
          langs={{
            en: 'English',
            nl: 'Dutch',
            de: 'Deutsch',
            fr: 'French'
          }}
        />
      </InjectApolloClientCustomer>
    )
  },
  {
    label: 'Console',
    key: 'graphql-console',
    allowedRoles: ['ADMIN'],
    routeComponent: AuthenticatedRouteBase,
    render: (): JSX.Element => <GraphQLCustomerPage />
  }
];

const extraRoutes = [
  {
    label: 'App',
    key: 'app/*',
    render: (): JSX.Element => <AppPage />
  }
];

export const App: React.FunctionComponent = () => (
  <MainLayout
    pages={pages}
    logo={Logo}
    extraWrapper={ProvideSelectedCustomer}
    extraMenuItems={CustomerSelect}
    extraRoutes={extraRoutes}
  />
);
