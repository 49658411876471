import { gql } from 'apollo-boost';

export const productQuery = gql`
  query($productId: String!) {
    product(productId: $productId) {
      id
      externalData {
        i18n {
          langMap_en: lang(langCode: "en") {
            name
          }
        }
      }
    }
  }
`;

export interface ProductQueryType {
  id: string;
  externalData: {
    i18n: {
      langMap_en: {
        name: string;
      };
    };
  };
}
