import { InjectApolloClient } from 'lib';
import React from 'react';
import { useSelectedCustomer } from 'util/selectedCustomer';

export const InjectApolloClientCustomer: React.FC = (props) => {
  const { selectedCustomer } = useSelectedCustomer();

  if (!selectedCustomer) {
    return null;
  }
  const extraHeaders = {
    'X-Platform-Customer-Code': selectedCustomer.id
  };
  return (
    <InjectApolloClient extraHeaders={extraHeaders}>
      {props.children}
    </InjectApolloClient>
  );
};
