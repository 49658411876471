import React from 'react';
import { PageHeader } from 'antd';
import { useSelectedCustomer } from 'util/selectedCustomer';
import { GraphQL } from 'lib';

export const GraphQLCustomerPage: React.FC = () => {
  const { selectedCustomer } = useSelectedCustomer();

  if (!selectedCustomer) {
    return null;
  }
  const extraHeaders = {
    'X-Platform-Customer-Code': selectedCustomer.id
  };

  return (
    <>
      <PageHeader
        title="GraphQL Query console"
        subTitle="Test and validate GraphQL queries"
        backIcon={false}
      ></PageHeader>
      <div className="header-padded">
        <GraphQL extraHeaders={extraHeaders} />
      </div>
    </>
  );
};
