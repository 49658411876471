import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Customer } from 'types/customer';

export interface SelectedCustomerContextType {
  initiating: boolean;
  managedCustomers: Customer[];
  selectedCustomer: Customer | null;
  setManagedCustomers: (customers: Customer[]) => void;
  setSelectedCustomer: (id: string) => void;
}

const LOCAL_STORAGE_VALUE_KEY = 'SELECTED_CUSTOMER';

const clearLocalStorageValue = () => {
  localStorage.removeItem(LOCAL_STORAGE_VALUE_KEY);
};

const setLocalStorageValue = (value: string) => {
  localStorage.setItem(LOCAL_STORAGE_VALUE_KEY, value);
};

const getLocalStorageValue = (): string | null => {
  const value = localStorage.getItem(LOCAL_STORAGE_VALUE_KEY);
  if (!value) {
    clearLocalStorageValue();
    return null;
  }
  return value;
};

const useProvideSelectedCustomer = (): SelectedCustomerContextType => {
  const [initiating, setInitiating] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [managedCustomers, setManagedCustomers] = useState<Customer[]>([]);
  useEffect(() => {
    if (managedCustomers.length === 0) {
      return;
    }
    const localStorageValue = getLocalStorageValue();
    const customerToSelect =
      localStorageValue !== null &&
      managedCustomers.map((c) => c.id).includes(localStorageValue)
        ? managedCustomers.filter((c) => c.id === localStorageValue)[0]
        : managedCustomers[0];
    setSelectedCustomer(customerToSelect);
    setLocalStorageValue(customerToSelect.id);
    setInitiating(false);
  }, [managedCustomers]);

  const setSelectedCustomerById = useCallback(
    (id: string) => {
      const matching = managedCustomers.filter((c) => c.id === id);
      if (matching.length === 0) {
        return;
      }
      setSelectedCustomer(matching[0]);
      setLocalStorageValue(id);
    },
    [managedCustomers]
  );
  return {
    initiating,
    selectedCustomer,
    managedCustomers,
    setManagedCustomers,
    setSelectedCustomer: setSelectedCustomerById
  };
};

const SelectedCustomerContext = createContext<SelectedCustomerContextType>({
  initiating: true,
  managedCustomers: [],
  selectedCustomer: null,
  setManagedCustomers: () => {
    throw new Error('CONTEXT NOT INITIATED');
  },
  setSelectedCustomer: () => {
    throw new Error('CONTEXT NOT INITIATED');
  }
});

export const ProvideSelectedCustomer: React.FC = ({ children }) => {
  const selectedCustomer = useProvideSelectedCustomer();
  return (
    <SelectedCustomerContext.Provider value={selectedCustomer}>
      {children}
    </SelectedCustomerContext.Provider>
  );
};

export const useSelectedCustomer = (): SelectedCustomerContextType =>
  useContext(SelectedCustomerContext);
